import React from "react";
import TopNavbar from "../components/TopNavbar";
import SubLayout from "../components/SubLayout";
import Layout from "../components/Layout";
import classes from "../css/TermsPage.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";

const TermsPage = () => {
  const navigate = useNavigate();
  const navigateHandler = (path) => {
    navigate(path);
  };
  return (
    <Layout>
      <SubLayout>
        <TopNavbar />

        <div className={classes.terms_container}>
          <div className={classes.navbar}>
            <ArrowBackIcon
              className={classes.back_icon}
              onClick={() => navigateHandler("/home")}
            />
            <p className={classes.navbar_title}>T&Cs</p>
          </div>
          {/* <p className={classes.term}>1). Hi</p> */}
        </div>
        <Footer active={2} />
      </SubLayout>
    </Layout>
  );
};

export default TermsPage;
