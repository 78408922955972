import React from "react";
import classes from "../css/SubscriptionInfo.module.css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { getCookie } from "../utils/helper";

const SubscriptionInfo = ({ pack }) => {
  return (
    <div className={classes.subscription_container}>
      <div className={classes.subscription_sub_container}>
        <div className={classes.number_pack_container}>
          <h3 className={classes.number}>{getCookie("msisdn")}</h3>
          <div className={classes.icon_pack_container}>
            <AccessTimeIcon className={`${classes.icon}`} />
            <p className={classes.pack}>
              Subscribed to {pack ? pack : "undefined"} Package
            </p>
          </div>
        </div>
        <div className={classes.avatar_container}>
          <img
            src="/assets/images/name.png"
            alt=""
            className={classes.avatar}
          />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionInfo;
