import React from "react";
// import Swiper from 'swiper';
import classes from "../css/Carousel.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useNavigate } from "react-router-dom";

const CarouselComponent = ({ games }) => {
  const navigate = useNavigate();
  const navigateHandler = (id) => {
    navigate(`/game/${id}`);
  };
  return (
    <Swiper
      slidesPerView={"auto"}
      spaceBetween={30}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
      className="mySwiper"
    >
      {games?.map((game, idx) => {
        return (
          <SwiperSlide onClick={() => navigateHandler(game?.categoryid)}>
            <img
              src={
                idx % 2 == 0
                  ? "/assets/images/carousel_img1.jpeg"
                  : "/assets/images/carousel_img2.jpeg"
              }
              // src="/assets/images/carousel_img1.jpeg"
              alt="game"
            />
            <div className={classes.data}>
              <h3 className={classes.h3}>Free {game?.categoryname} Games</h3>
              <p className={classes.p}>Play For Fun</p>
              <button className={classes.button}>Play Now</button>
            </div>
          </SwiperSlide>
        );
      })}

      {/* <SwiperSlide>
        <img src="/assets/images/carousel_img2.jpeg" alt="" />
        <div className={classes.data}>
          <h3 className={classes.h3}>Free Games</h3>
          <p className={classes.p}>Play For Fun</p>
          <button className={classes.button}>Play Now</button>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img src="/assets/images/carousel_img1.jpeg" alt="" />
        <div className={classes.data}>
          <h3 className={classes.h3}>Free Games</h3>
          <p className={classes.p}>Play For Fun</p>
          <button className={classes.button}>Play Now</button>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img src="/assets/images/carousel_img2.jpeg" alt="" />
        <div className={classes.data}>
          <h3 className={classes.h3}>Free Games</h3>
          <p className={classes.p}>Play For Fun</p>
          <button className={classes.button}>Play Now</button>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img src="/assets/images/carousel_img1.jpeg" alt="" />
        <div className={classes.data}>
          <h3 className={classes.h3}>Free Games</h3>
          <p className={classes.p}>Play For Fun</p>
          <button className={classes.button}>Play Now</button>
        </div>
      </SwiperSlide> */}
    </Swiper>
  );
};

export default CarouselComponent;
