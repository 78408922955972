import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import SubLayout from "../components/SubLayout";
import TopNavbar from "../components/TopNavbar";
import classes from "../css/UnSubSuccessPage.module.css";
import DoneIcon from "@mui/icons-material/Done";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const UnSubSuccessPage = () => {
  const location = useLocation();
  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  const navigateHandler = (path) => {
    navigate(path);
  };

  useEffect(() => {
    if (location?.state) {
      setStatus(location?.state?.status);
    } else {
      navigate("/home");
    }
  }, [location]);
  return (
    <Layout>
      <SubLayout>
        <TopNavbar />
        <div className={classes.container}>
          {status == "Success" ? (
            <div className={classes.unsubscribe_container}>
              <div className={classes.image_text_container}>
                <img
                  src="/assets/images/cat.png"
                  alt="cat"
                  className={classes.cat}
                />
                <div className={classes.text_container}>
                  <div className={classes.icon_container}>
                    <DoneIcon className={classes.icon} />
                  </div>
                  <h3 className={classes.text}>Unsubscribed Successfully!</h3>
                </div>
              </div>
              <button
                onClick={() => navigateHandler("/")}
                className={`${classes.btn} ${classes.btn2}`}
              >
                Back
              </button>
            </div>
          ) : status == "Failed" ? (
            <div className={classes.unsubscribe_container}>
              <div className={classes.image_text_container}>
                <img
                  src="/assets/images/cat.png"
                  alt="cat"
                  className={classes.cat}
                />
                <div className={classes.text_container}>
                  <div className={classes.icon_container_2}>
                    <CloseIcon className={classes.icon} />
                  </div>
                  <h3 className={classes.text}>Failed To Unsubscribe!</h3>
                </div>
              </div>
              <button
                onClick={() => navigateHandler(-1)}
                className={`${classes.btn} ${classes.btn2}`}
              >
                Back
              </button>
            </div>
          ) : (
            <div className={classes.unsubscribe_container}>
              <div className={classes.image_text_container}>
                <img
                  src="/assets/images/cat.png"
                  alt="cat"
                  className={classes.cat}
                />
                <div className={classes.text_container}>
                  <div className={classes.icon_container_2}>
                    <CloseIcon className={classes.icon} />
                  </div>
                  <h3 className={classes.text}>Something Went Wrong!</h3>
                  <p className={classes.p}>Failed to Unsubscribe!</p>
                </div>
              </div>
              <button
                onClick={() => navigateHandler(-1)}
                className={`${classes.btn} ${classes.btn2}`}
              >
                Back
              </button>
            </div>
          )}
        </div>
      </SubLayout>
    </Layout>
  );
};

export default UnSubSuccessPage;
