import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import SubLayout from "../components/SubLayout";
import TopNavbar from "../components/TopNavbar";
import Footer from "../components/Footer";
import classes from "../css/Games.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getGamesApi } from "../http/http";
import Loader from "../components/Loader";

const GameCategory = () => {
  const [loader, setLoader] = useState(true);
  const [games, setGames] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchGames = async () => {
    try {
      setLoader(true);
      const response = await getGamesApi();
      console.log(response, "response");
      setGames(() =>
        response?.data?.games.filter((game) => game?.categoryid == id)
      );
      setLoader(false);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchGames();
  }, [id]);

  console.log(games, "games");

  const arr = [
    {
      src: "/assets/images/gameImg.jpeg",
      name: "Arcade",
    },
    {
      src: "/assets/images/sport.jpeg",
      name: "Sports",
    },
    {
      src: "/assets/images/strategy.png",
      name: "Strategy",
    },
    {
      src: "/assets/images/adventure.jpeg",
      name: "Adventure",
    },
    {
      src: "/assets/images/puzzle.png",
      name: "Puzzle",
    },
    {
      src: "/assets/images/mind_game.png",
      name: "Mind Game",
    },
  ];

  return (
    <Layout>
      {loader ? (
        <Loader />
      ) : (
        <SubLayout>
          <TopNavbar title="Games" />
          <div className={classes.container}>
            <div className={classes.top}>
              <div className={classes.cat}>{games[0]?.categoryname}</div>

              <div className={classes.seeAll}>
                <Link to="/allgames">
                  <p
                    style={{
                      cursor: "pointer",
                      color: "#E92230",
                      fontSize: "1.5rem",
                    }}
                  >
                    See All
                  </p>
                </Link>
              </div>
            </div>

            <div className={classes.games}>
              {games[0]?.data?.map((item, index) => (
                <div
                  className={classes.card}
                  key={index}
                  onClick={() => navigate(`/game/${item?.id}`)}
                >
                  <img src={item?.thumbnailUrl} alt={item?.title} />
                  <p>{item?.title}</p>
                </div>
              ))}
            </div>
          </div>
        </SubLayout>
      )}
      <Footer active={1} />
    </Layout>
  );
};

export default GameCategory;
