import axios from "axios";

// const BASE_URL = `http://5.189.169.12:9374`;
// const BASE_URL = `http://142.132.149.12:9374`;
// const BASE_URL = `http://localhost:9374`;
// const BASE_URL=`/`;
const BASE_URL = `https://backend.yonumbers.com`;

export async function loginApi(data) {
  const response = await axios.post(`${BASE_URL}/authenticate`, data);
  return response;
}

export async function subscribeApi(data) {
  const response = await axios.post(`${BASE_URL}/subscribe`, data);
  return response;
}

export async function matchOtpApi(data) {
  const response = await axios.post(`${BASE_URL}/verifyOtp`, data);
  return response;
}

export async function priceApi() {
  const response = await axios.get(`${BASE_URL}/price`);
  return response;
}

export async function getGamesApi() {
  const response = await axios.get(`${BASE_URL}/getGames`);
  return response;
}

export async function hitGameApi(gameid) {
  const response = await axios.get(`${BASE_URL}/play/${gameid}`);
  return response;
}

export async function unsubApi(number) {
  const response = await axios.get(`${BASE_URL}/unsubscription/${number}`);
  return response;
}

export async function getDetails(number) {
  const response = await axios.get(`${BASE_URL}/getDetails/${number}`);
  return response;
}
